// @mui
import IconButton from '@mui/material/IconButton';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
// hooks
import useSettings from '../../../hooks/useSettings';
// ----------------------------------------------------------------------

export default function DarkModeToggle() {
  const { themeMode, onToggleMode } = useSettings();

  return (
    <IconButton onClick={onToggleMode}>
      {themeMode === 'dark' ? <LightModeIcon /> : <DarkModeIcon />}
    </IconButton>
  );
}
