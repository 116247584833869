// LAYOUT
// ----------------------------------------------------------------------

export const NETWORK = "Terra"
export const SB_STAKE_URL = "https://station.terra.money/validator/terravaloper13n2fsvfvj28eqvkjejhqlxf3pch3muxkxudacc";
export const NETWORK_ICON = 'terra.png'
export const EXPLORER_OPERATOR_URL = 'https://chainsco.pe/terra2/validator'
export const EXPLORER_ACCOUNT_URL = 'https://chainsco.pe/terra2/account'
export const EXPLORER_ADDRESS_URL = 'https://chainsco.pe/terra2/address'
export const EXPLORER_TX_URL = 'https://chainsco.pe/terra2/tx'
export const GOV_PROP_URL = 'https://station.terra.money/proposal/phoenix-1'
export const PROD_API_URL = "";

export const NETWORK_OPTIONS = [
  { label: 'Terra', link: 'https://terra.stakebin.io' },
  { label: 'Terra Classic', link: 'https://terraclassic.stakebin.io' },
];

export const BC_HREF_LINKS = {
  "Terra": "/terra",
  "Validators": "/terra/validators",
}

export const HEADER = {
  MOBILE_HEIGHT: 48,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 76,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 76 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

export const KNOWN_ACCOUNT_ADDRESSES = {
  terra1fl48vsnmsdzcv85q5d2q4z5ajdha8yu3nln0mh: "Bonded Tokens Pool",
  terra1tygms3xhhs3yv487phx3dw4a95jn7t7l8l07dr: "Unbonding Tokens Pool",
  terra1jv65s3grqf6v6jl3dp4t6c9t9rk99cd8pm7utl: "Distribution Module",
  terra17xpfvakm2amg962yls6f84z3kell8c5lkaeqfa: "Fee Collector",
  terra10atxpzafqfjy58z0dvugmd9zf63fycr6uvwhjm: "Binance 1",
  terra1jrq7xa63a4qgpdgtj70k8yz5p32ps9r7mlj3yr: "Binance 2",
  terra15s66unmdcpknuxxldd7fsr44skme966tdckq8c: "Binance 3",
  terra1u0p7xuwlg0zsqgntagdkyjyumsegd8agzhug99: "Binance 4",
  terra1fax8l6srhew5tu2mavmu83js3v7vsqf9yr4fv7: "Binance 5",
  terra132wegs0kf9q65t9gsm3g2y06l98l2k4treepkq: "Binance 6",
  terra1l89hprfccqxgzzypjzy3fnp7vqpnkqg5vvqgjc: "Binance 7",
  terra1ns7lfvrxzter4d2yl9tschdwntcxa25vtsvd8a: "Binance 8",
  terra1vuvju6la7pj6t8d8zsx4g8ea85k2cg5u62cdhl: "Binance 9",
  terra1lzdux37s4anmakvg7pahzh03zlf43uveq83wh2: "Binance 10",
  terra1ky3qcf7v45n6hwfmkm05acwczvlq8ahnq778wf: "Binance 11",
  terra17m8tkde0mav43ckeehp537rsz4usqx5jayhf08: "Binance 12",
  terra1urj8va62jeygra7y3a03xeex49mjddh3eul0qa: "Binance 13",
  terra10wyptw59xc52l86pg86sy0xcm3nm5wg6a3cf7l: "Binance 14",
  terra1sujaqwaw7ls9fh6a4x7n06nv7fxx5xexwlnrkf: "Binance 15",
  terra1qg59nhvag222kp6fyzxt83l4sw02huymqnklww: "Binance 16",
  terra1dxxnwxlpjjkl959v5xrghx0dtvut60eef6vcch: "Binance 17",
  terra1y246m036et7vu69nsg4kapelj0tywe8vsmp34d: "Binance 18",
  terra1j39c9sjr0zpjnrfjtthuua0euecv7txavxvq36: "Binance 19",
  terra1t0jthtq9zhm4ldtvs9epp02zp23f355wu6zrzq: "Binance 20",
  terra12dxclvqrgt7w3s7gtwpdkxgymexv8stgqcr0yu: "Binance 21",
  terra1az3dsad74pwhylrrexnn5qylzj783uyww2s7xz: "Binance 22",
  terra1ttq26dq4egr5exmhd6gezerrxhlutx9u90uncn: "Binance 23",
  terra13e9670yuvfs06hctt9pmgjnz0yw28p0wgnhrqn: "Binance 24",
  terra1skmktm537pfaycgu9jx4fqryjt6pf77ycpesw0: "Binance 25",
  terra14q8cazgt58y2xkd26mlukemwth0cnvfqmgz2qk: "Binance 26",
  terra163vzxz9wwy320ccwy73qe6h33yzg2yhyvv5nsf: "Binance 27",
  terra1kj43wfnvrgc2ep94dgmwvnzv8vnkkxrxmrnhkp: "Binance 28",
  terra1gu6re549pn0mdpshtv75t3xugn347jghlhul73: "Binance 29",
  terra1gft3qujlq04yza3s2r238mql2yn3xxqepzt2up: "Binance 30",
  terra174pe7qe7g867spzdfs5f4rf9fuwmm42zf4hykf: "Binance 31",
  terra1ju68sg6k39t385sa0fazqvjgh6m6gkmsmp4lln: "Binance 32",
  terra1dlh7k4hcnsrvlfuzhdzx3ctynj7s8dde9zmdyd: "Binance 33",
  terra18wcdhpzpteharlkks5n6k7ent0mjyftvcpm6ee: "Binance 34",
  terra1xmkwsauuk3kafua9k23hrkfr76gxmwdfq5c09d: "Binance 35",
  terra1t957gces65xd6p8g4cuqnyd0sy5tzku59njydd: "Binance 36",
  terra1s4rd0y5e4gasf0krdm2w8sjhsmh030m74f2x9v: "Binance 37",
  terra15jya6ugxp65y80y5h82k4gv90pd7acv58xp6jj: "Binance 38",
  terra14yqy9warjkxyecda5kf5a68qlknf4ve4sh7sa6: "Binance 39",
  terra1yxras4z0fs9ugsg2hew9334k65uzejwcslyx0y: "Binance 40",
  terra1p0vl4s4gp46vy6dm352s2fgtw6hccypph7zc3u: "Binance 41",
  terra1hhj92twle9x8rjkr3yffujexsy5ldexak5rglz: "Binance 42",
  terra18vnrzlzm2c4xfsx382pj2xndqtt00rvhu24sqe: "Binance 43",
  terra1ncjg4a59x2pgvqy9qjyqprlj8lrwshm0wleht5: "Binance 44",
  terra19l7hzwazq5j0dykfldcwrk2927xwcjd0kt0vt9: "Binance 45",
  terra1frh79vmtur5fmrghz6gfjvfhpa3u2c0uemv4af: "Binance 46",
  terra1chq5ps8yya004gsw4xz62pd4psr5hafe7kdt6d: "Kucoin 1",
  terra1rvxcszyfecrt2v3a7md8p30hvu39kj6xf48w9e: "Kucoin 2",
  terra1v74a5u6qsjuj4gu6at9yn4p35uctcz82f02fau: "Kucoin 3",
  terra13s4gwzxv6dycfctvddfuy6r3zm7d6zklynzzj5: "OKX 1",
  terra1cymh5ywgn4azak74h4gsrnakqgel4y9ssersvx: "LFG 1",
  terra1gr0xesnseevzt3h4nxr64sh5gk4dwrwgszx3nw: "LFG 2",
  terra1hgxxhyvjdpx06l28yx8u9dkpnx5a265duyx9tm: "LFG 3",
  terra1q0phshknw0pelzrwlezmt7vnmr8jh9vjgezt3z: "LFG 4",
  terra1qy36laaky2ns9n98naha2r0nvt3j7q3fpxfs2e: "LFG 5",
  terra100q9kymr3ragcftenzrwyn0s34jard347sqgyf: "TFL 1",
  terra10cn33pugeet6sgmdmswqckgka27gtt4n9l48vg: "TFL 2",
  terra10zzfuednzhkmwn4tqks2mukstr02r5jklpmh2f: "TFL 3",
  terra120fas4lu0p48lc4cwkhk487raxt87weu47cflv: "TFL 4",
  terra12jjl92hxfur0v4h5xu0nxlrxgnch3kge8yex99: "TFL 5",
  terra12qmfcxy8kahwt44tn4aq4t6kdmtyer38xnrh7m: "TFL 6",
  terra12vp8yq4wygfl9gssjs8k3kagrv3kx7ppexzqv2: "TFL 7",
  terra13t7x0glk0e6ln5zwdc8uudmylnhmmqf3tfqdc2: "TFL 8",
  terra13u3p30c2tn9m8dmru6mfyhmqs379qm43vrx7l7: "TFL 9",
  terra13u66u4knssnws7n7w0n38mzyyqak5ygp807gyl: "TFL 10",
  terra13vpm23s7rw5gqp9ckhqngjesxcuzatytknqpfg: "TFL 11",
  terra1404rdkfaj5ag8ukejtqutx0wszf44y6jwd7evq: "TFL 12",
  terra14l46jrdgdhaw4cejukx50ndp0hss95ekt2kfmw: "TFL 13",
  terra14ny376fe7hys2zqxwc87zp2achp9tju5q6jul4: "TFL 14",
  terra14tkf4veq2sj2q5fy0kf2xllz5hqwaf3efaatev: "TFL 15",
  terra14tlthgtg6ep6xnqptyg8dp3gcq4jxvgqmskwkd: "TFL 16",
  terra150nxdh7mnsz635y7yku7jatr7mx62lpr4pmajr: "TFL 17",
  terra15ffq0drrl5alm7txhel29jjuqpgsezprdc7f3g: "TFL 18",
  terra15med60egxczkvpj69v20jep09xw4e09v0tkgnf: "TFL 19",
  terra15n30nk24z4usykrmatk5phlq6dnv85vltdaqxm: "TFL 20",
  terra15nuyg54943gy2fj6u8cg8emeql8g6cpw2s2gnp: "TFL 21",
  terra15w7zj0sppxndaw9f2xr2maxtam9sd58r4fj27q: "TFL 22",
  terra16vs6rf0ldy8esfw0rjtefmsfj86sx2nwkf9vfz: "TFL 23",
  terra17kd2zh4dawqx23mp68jmau4geftmyz8vuccecw: "TFL 24",
  terra17llpzqmqqect6m3jun5ygkpgad9302l8c366wx: "TFL 25",
  terra17p4mqd7yl9m0r7cfv0nf9s9zae3d3gm4tmyg2a: "TFL 26",
  terra17slsfmtuavq9dw607aqu35v82pnkaavg3uywqk: "TFL 27",
  terra17uv6pjyfpnck0qvqe4kzwvf8cjy0cm7tn2vg30: "TFL 28",
  terra186y5e0vsg2c8upmdvsg3ler6f5vjvngdrsrcl2: "TFL 29",
  terra1885dgdvn5u8sjfaefvr39arssaxgqmd29ht0aa: "TFL 30",
  terra18kgwjqrm7mcnlzcy7l8h7awnn7fs2pvdl2tpm9: "TFL 31",
  terra18w7z9prrjzncz3mkqh7e65q3mc6j386y0qyw9a: "TFL 32",
  terra19f9uy05e22lmuwwas5y6gyegn0de5mnx294gqk: "TFL 33",
  terra19prvtg0g32x6044xw87nj2xpslgynads5qqqqq: "TFL 34",
  terra1a4hsgd8h4tapvzplafxk994sn8lcxa2lpv6g95: "TFL 35",
  terra1anllr0tt8r2my88kp6kmdwdnewnja5246djj99: "TFL 36",
  terra1asa47p67tgrl0858295k3wu8gvyypgc5eqre9w: "TFL 37",
  terra1ayg93fkswx733p6ga4yay9n28gns96d8n5238y: "TFL 38",
  terra1dp0taj85ruc299rkdvzp4z5pfg6z6swaed74e6: "TFL 39",
  terra1dtzfwgzt8xa70zkm8gqzwz0n4zrqtngdpqejx5: "TFL 40",
  terra1dvghtnsqr6eusxxhqcmuhwmpw26rze8kgap823: "TFL 41",
  terra1e028300g5kaef05vycatgz8tnrvmwmfstafk52: "TFL 42",
  terra1edl99rx5nfjq26mmydck4nr5w6enf3wwttrqn8: "TFL 43",
  terra1f4hkav2s46kf7jpaeu9nwrzy0upaa96el76k48: "TFL 44",
  terra1f6y8w7lyhe47uu34ru8gcwqmz2qp5czjkh0c05: "TFL 45",
  terra1f9033snquell52kxnv5cqauuq0c8gsf5cz30nv: "TFL 46",
  terra1fqgwjedr6wc63f5hasmurzc7kfqe6zqjz2ets7: "TFL 47",
  terra1fucr7wdhrm4q2wa73y4fygwc0qnlrqryqcukjv: "TFL 48",
  terra1g2ghu8s3c4thlta9v3zlnk5sr9jq7gzaymnjqw: "TFL 49",
  terra1g5dmf4qpmdrcw77d3ty6djrgujt8r2n3lgyknj: "TFL 50",
  terra1g9p9c5hjw4m3v9l65lmxt2g89vheashp6yfukm: "TFL 51",
  terra1grpgrd2ch2hqya24q99q7gcfk0pfsmq5tuq573: "TFL 52",
  terra1h5el75ha0zdwfawgxs6z0shqcw3m8r53f0wqrl: "TFL 53",
  terra1j20ryn8358egr3xux0a4qdpje9y5h7ed277k8a: "TFL 54",
  terra1jlvhvntwkkr5y46gl9duleftkxj7qaxw9t52ev: "TFL 55",
  terra1ju3vpfrurdt5ekm68zqaz2al2hjul2vyc2eeeq: "TFL 56",
  terra1k72puv50tjck4jxluq2qmjkjvvfpqe4y6ffgtl: "TFL 57",
  terra1kakd0l3ll92gamdz8zq7pr7lmghxhkna7jjj39: "TFL 58",
  terra1kflkz6dyc84snr5rwqx3dn8dd92m6lhqauhpen: "TFL 59",
  terra1kh3mdlm056je243kyv9glegvd494tf65vtq4lh: "TFL 60",
  terra1kvwkvurw4xexw69ef772p95jajnjjjsq6d5uca: "TFL 61",
  terra1kznvssy2wuzjq96d8esnwvpufsdf5vnejmwes7: "TFL 62",
  terra1l34cc5rc7lfp5f02mrx825w0n3rzpxjttjfrex: "TFL 63",
  terra1lcn74u0pysevu0nvcum5cl76xhwyk6jfjxrjj9: "TFL 64",
  terra1le5u9a5xsx6lzwa3sfahrmcqdhqc674tq7mtun: "TFL 65",
  terra1lewnh53gt0hzgjejrtwdz8q7dennz09s6ds4k2: "TFL 66",
  terra1lk052emj0a5xspuusqv7tls50hxcladp63p78w: "TFL 67",
  terra1ly9s5rpk0nn2sv8v5kxhrwkp3d48ytnxn6e5yj: "TFL 68",
  terra1mer7nd843n5g3v37xxtd3hude6t5rf4cq3mnae: "TFL 69",
  terra1mv8kytdmjtjflj67wml3f7fwglcm0tyksur2s7: "TFL 70",
  terra1n4s64hnryvll5494h0vyathctzxcgvxyvlzcve: "TFL 71",
  terra1ndmlkwnhwtq6ec2ww9na46w06pngww79z5722c: "TFL 73",
  terra1nexqdq252acp09m9eler7jhg9kd3rqfzkek9r0: "TFL 74",
  terra1njf8w6j6533e30mdmjs26qhg7hz3mvhcz0wfe6: "TFL 75",
  terra1np5l2y7y2jm5sdl2hklyjx0wm4swhmyufwfju9: "TFL 76",
  terra1pahtsf4340k0txffcmgn7hc9e9ujvfqjj32x26: "TFL 77",
  terra1pe5q4xlu5g8vtk944mmmz8kryrgtcqup5t4j26: "TFL 78",
  terra1pp8d7k749aahvdawpnry89auhdyu43ru6at93g: "TFL 79",
  terra1qa0eas8f496jhdkx5dp7r6qka7edvdpzmrqs4h: "TFL 80",
  terra1qmhrqjyjadep9p6qklp2kyzfen2kuwy06qnpjr: "TFL 81",
  terra1qry8z0ysw24498yf5dnqadextpjtt8wf4gjpzh: "TFL 82",
  terra1r3mdl2q46mx5z8y8d6enm35t6g76wqtn43008u: "TFL 83",
  terra1t28h4fg8gjpggvq985d2zz569qj8hpxnsxcx93: "TFL 84",
  terra1t60qeppglvwpa4ha8uhcavrct87lnuyyfnvg6r: "TFL 85",
  terra1t6gktppdd82wjnz3sm4792ycs44xc232773sae: "TFL 86",
  terra1t8szgklntcwxyfyflucuq895gpjr8wn6tsqxye: "TFL 87",
  terra1tdu6f7nffenxw7aahumtjklnzfcdw24k8836q5: "TFL 88",
  terra1tqqvkezdfhk6zpns2qawasn2legcnnsatxwdwt: "TFL 89",
  terra1tthcqld37wghyg3r5q5wj2f88tl5xqmcq4gweg: "TFL 90",
  terra1tuwmm8gy8e5sjza8nm2glpkfsl0h074ge7jh85: "TFL 91",
  terra1txdprxd6haeqmsv9w25m5lyznk9gc8wnvkk9ee: "TFL 92",
  terra1u9sanfs5qul9yupat9ctegy0kr4gsh7f5npzql: "TFL 93",
  terra1uhdaxsdkd05jypjuqypzyhelgtchnvue57rw5p: "TFL 94",
  terra1upvtdfdedsaupukkgug6zsn39y88cqty0c9qmy: "TFL 95",
  terra1uwvssna69j3yk7u6lhvnywleek43ajsgly7ycf: "TFL 96",
  terra1v49w0m38fe87edhqdv30w96wq0pz6u6548q9gj: "TFL 97",
  terra1v9ku44wycfnsucez6fp085f5fsksp47u9x8jr4: "TFL 98",
  terra1wlc64fe7p6u37qq0vykeksmr40d6a6cscfcn28: "TFL 99",
  terra1wqatrjkjgwc9998ft8kmrk2p78a0luexk5eckt: "TFL 100",
  terra1x04xgtwlw72gtfzrq7nfwmr6eexla8ecljw28z: "TFL 101",
  terra1xah6rv29d6ztfhkx7k2u8grpkmpket2pqyz49x: "TFL 102",
  terra1xmr5j80l7446zqrn2093vl7tl37ulsdv97y69k: "TFL 103",
  terra1xp0t0suwjfsm708ptxtfhheq2d72fsuehvttgu: "TFL 104",
  terra1xpcsvh9w4cg07rq9ne8pvgg5277e0jtpk0kjcp: "TFL 105",
  terra1xvmdjsdp58sanlzvnx6g79nyf2zk7cr5l78mxs: "TFL 106",
  terra1yca6ukeesx6p2393amm0y97m0qyqmntuzwenrx: "TFL 107",
  terra1ypl9vcyx4e9epgdevdqnc36dd3enxa0a7dh6pg: "TFL 108",
  terra1yrqggdm0zhgyr837h87xy3m00layz5g6yhsvfc: "TFL 109",
  terra1ywzl73n563gpdeh7dnqx79j3v9hhlj2fayct8e: "TFL 110",
  terra1yx9jy2rq4qp9fsuws4k6q8em2tpqqn5zkjfnwj: "TFL 111",
  terra1z2t3mmn0k8uzpzpspamy9pz0n97k8y7s6atxqv: "TFL 112",
  terra1z32l7kapluzykwv9p7cmrq6myqcxt2j3jzgr59: "TFL 113",
  terra1zaj0537x67s9vjtg06d2xu0fagvnqacrecq0zd: "TFL 114",
  terra1zd46n9v45lsqsxrcje3ke6ejlxm7nhtqr2dlgq: "TFL 115",
  terra1zkjyw4tszwq38rn6l083lt2u44j5fed69xgvke: "TFL 116",
  terra1zu47exa76mv20xt5hywhrs0r87wu52pnk5uxt5: "TFL 117",
  terra1zwh52hgtkmhqhha9aydmt70leuxujdnf8yfedn: "TFL 118",
  terra1zz2nf34fjkjygkg0kplkrr29ycxarmct6kafvj: "TFL 119",
}

export const KNOWN_CONTRACT_ADDRESSES = {
  terra1ywxkhegrt234mgmdekx60ur33ng6rsucq9ukxh0ud2s2lv7t5h5q4fawg5: "Warp Burner",
  terra10urrzgujlwd9wa6vh77yplqkyhu3df8l28mdqdh6ts99zprl6j2s5hcjs5: "Ura Router",
  terra1q2hjgq5sm7w04saj70gv0ur5tlz7r20854dfmsk8uv5u8cqnkuzskk7shc: "Galactic Mining Club",
  terra1nsuqsk6kh58ulczatwev87ttq2z6r3pusulg9r24mfj2fvtzd4uq3exn26: "Astroport ASTRO Token",
  terra18plp90j0zd596zt3zdsf0w9vvk5ukwlwzwkksxv9mdu8rscat9sqndk5qz: "Astroport Router 1",
  terra1j8hayvehh3yy02c2vtw5fdhz9f4drhtee8p5n5rguvg3nyd6m83qd2y90a: "Astroport Router 2",
  terra14x9fr055x5hvr48hzy2t4q7kvjvfttsvxusa4xsdcy702mnzsvuqprer8r: "Astroport Factory",
  terra13cw46g72kwtgln0540j9cqa79ham5k86jlx34e2pqukww6v0v3yseakged: "Astroport Pair 1",
  terra16n04evd52r67acm3t8dy2369msuzkapdjmg9rzr6ankmmmj6054s8q5zlc: "Astroport Pair 2",
  terra1vl6vdefwpznm87d8tmcxaqn7mjtqlvyn4a485x6ux54lnusx8eespp0569: "Astroport Pair 3",
  terra1hz6ml6kqrr3gdl7wzp97352mql5lkd3qjas8fpkkqumvmlxf8qpst0zcah: "Axelar Realted 1",
  terra1ag6jlr5qvetcd5m3str83tzd0yslvdnpukcn808jv3yhlepkx3jqpxzkaf: "Enterprise Governance Controller",
  terra1d36lwl0nk9g7qc7d2j96f0fd4wgeq9058j8phype6dtk7dlgqsjsnrh0te: "Trick or Treat: A Midnight Enigma",
  terra1p37jrwlaqpklzlu4rwjyjrmzuezdgk3pyuyk2zclc4rda6awkm3qnj6f0a: "White Whale Pool Router",
  terra199pgv9dymcg9q8xtwsxk7yakazmvlf5ptkqh4zadcv7k0yqsal2q6tq7mv: "Solid Oracle v2",
  terra1lxx40s29qvkrcj8fsa3yzyehy7w50umdvvnls2r830rys6lu2zns63eelv: "Lion DAO ROAR Token",
  terra1ej4cv98e9g2zjefr5auf2nwtq4xl3dm7x0qml58yna2ml2hk595s7gccs9: "BBL Necropolis",
  terra1ctelwayk6t2zu30a8v9kdg3u2gr0slpjdfny5pjp7m3tuquk32ysugyjdg: "Wormhole wSOL Token",
  terra1mg93d4g69tsf3x6sa9nkmkzc9wl38gdrygu0sewwcwj6l2a4089sdd7fgj: "Warp Controller",
  terra13taf05fedehdfsguu0v24ssmrs9xfzn6wewwkxxlsdy2qmudhwfsvvgdm5: "Warp Resolver",
  terra19yxffalxzu88n5lnj40trehpryemqsz7pnnwxp8v73hxz0rl2u9q5qqwh4: "Community Pool Vesting Smart Contract",
  terra1yv5fyftazjsy3uslzwrsaqcahn8mht87kf7jzlh50yfnu7mqxymsja06dz: "TFL Liquidity Multisig",
}

export const KNOWN_ADDRESSES = { ...KNOWN_ACCOUNT_ADDRESSES, ...KNOWN_CONTRACT_ADDRESSES }

// SETTINGS
// Please remove `localStorage` when you set settings.
// ----------------------------------------------------------------------

export const defaultSettings = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeColorPresets: 'default',
  themeLayout: 'horizontal',
  themeStretch: false,
};

export const TIME_BUCKETS = ['1h', '4h', 'D', 'W', 'M']
export const TIME_INTERVALS = ['1D', '7D', '1M', '1Y']

// use local api server in dev mode
export const getApiUrl = () => {
  let url = PROD_API_URL;

  try {
    if (process.env && process.env.NODE_ENV === 'development') {
      url = `http://${window.location.hostname}:8902`;
    }
  }
  catch (err) {
    url = PROD_API_URL;
  };

  return url;
}
