import { lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/terra/stats')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/terra" replace />, index: true },
        {
          path: '/terra',
          children: [
            { element: <Navigate to="/terra/stats" replace />, index: true },
            { path: '/terra/stats', element: <TerraStatsPage /> },
            { path: '/terra/validators', element: <TerraValidatorsPage /> },
            { path: '/terra/supply', element: <TerraSupplyPage /> },
            { path: '/terra/transactions', element: <TerraTxPage /> },
            { path: '/terra/communitypool', element: <TerraCpPage /> },
            { path: '/terra/decentralization', element: <DecentralPage /> },
            { path: '/terra/wallets', element: <WalletsPage /> },
            { path: '/terra/val/*', element: <ValidatorDetailsPage /> },
            { path: '/terra/tools', element: <ToolsPage /> },
          ],
        },
        { path: '/steak', element: <SteakPage /> },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
  ]);
}

// pages
const TerraStatsPage = Loadable(lazy(() => import('../pages/terra/stats')));
const TerraValidatorsPage = Loadable(lazy(() => import('../pages/terra/validators')));
const TerraSupplyPage = Loadable(lazy(() => import('../pages/terra/supply')));
const TerraTxPage = Loadable(lazy(() => import('../pages/terra/transactions')));
const TerraCpPage = Loadable(lazy(() => import('../pages/terra/communityPool')));
const ValidatorDetailsPage = Loadable(lazy(() => import('../pages/terra/validatorDetails')));
const SteakPage = Loadable(lazy(() => import('../pages/steak/index')));
const ToolsPage = Loadable(lazy(() => import('../pages/terra/tools')));
const WalletsPage = Loadable(lazy(() => import('../pages/terra/wallets')));
const DecentralPage = Loadable(lazy(() => import('../pages/terra/decentralization')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
