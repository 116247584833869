import { LicenseInfo } from '@mui/x-license-pro';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { ChartStyle } from './components/chart';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import ThemeColorPresets from './components/ThemeColorPresets';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
// import MaintenancePopup from './components/MaintenancePopup';

// ----------------------------------------------------------------------

export default function App() {
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY);

  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <RtlLayout>
          <MotionLazyContainer>
            {/* <MaintenancePopup msg="This data is from Luna Classic. The newer LUNA chain will be indexed in coming weeks" /> */}
            <ProgressBarStyle />
            <ChartStyle />
            <ScrollToTop />
            <Router />
          </MotionLazyContainer>
        </RtlLayout>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}
