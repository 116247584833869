// @mui
import SvgIcon from '@mui/material/SvgIcon';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import EmailIcon from '@mui/icons-material/Email';
import { IconButton, Stack } from '@mui/material';
import { ReactComponent as DiscordDarkLogo } from '../../../assets/discord_dark.svg';
import { ReactComponent as DiscordLightLogo } from '../../../assets/discord_light.svg';
import useSettings from '../../../hooks/useSettings';

// ----------------------------------------------------------------------

export default function SocialButtons() {
  const { themeMode } = useSettings();

  return (
    <Stack direction={'row'} justifyContent={'center'} paddingBottom={1}>
      <IconButton
        target="_blank"
        href="https://twitter.com/StakeBin"
      >
        <TwitterIcon />
      </IconButton>
      <IconButton
        target="_blank"
        href="https://discord.gg/KTKc33PRev"
      >
        <SvgIcon>
          {themeMode === 'dark' ? <DiscordDarkLogo /> : <DiscordLightLogo />}
        </SvgIcon>
      </IconButton>
      <IconButton
        target="_blank"
        href="https://t.me/StakeBin"
      >
        <TelegramIcon />
      </IconButton>
      <IconButton
        target="_blank"
        href="mailto:contact@stakebin.io"
      >
        <EmailIcon />
      </IconButton>
    </Stack>
  );
}
