import PropTypes from 'prop-types';
// @mui
import { Stack, Button, Typography, Box } from '@mui/material';
// assets
import { SB_STAKE_URL } from '../../../config';
// ----------------------------------------------------------------------

NavbarStake.propTypes = {
  theme: PropTypes.object
};

export default function NavbarStake({ theme }) {
  return (
    <Box sx={{ px: 2.5, pb: 1, mt: 10 }}>
      <Stack
        alignItems="center"
        spacing={3}
        sx={{
          p: 2.5,
          pt: 5,
          borderRadius: 2,
          position: 'relative',
          bgcolor: theme.palette.grey[500_12],
        }}
      >
        <Box sx={{ textAlign: 'center' }}>
          <Typography gutterBottom variant="h6">
            Like this?
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Consider delegating your stake with StakeBin
          </Typography>
        </Box>

        <Button
          fullWidth
          href={SB_STAKE_URL}
          target="_blank"
          variant="contained"
        >
          Delegate With Us
        </Button>
      </Stack>
    </Box>
  );
}
