import { useState } from 'react';
// @mui
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import { Button, MenuItem, Typography, Link } from '@mui/material';
import MenuPopover from '../../../components/MenuPopover';
import { NETWORK, NETWORK_OPTIONS } from '../../../config';
// hooks

export default function NetworkMenu() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        onClick={(event) => setOpen(event.currentTarget)}
        endIcon={open ? <KeyboardArrowUp /> : <KeyboardArrowDownIcon />}
      >
        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
          Networks
        </Typography>
      </Button>

      <MenuPopover
        anchorEl={open}
        open={Boolean(open)}
        onClose={() => setOpen(false)}
      >
        {NETWORK_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            disabled={option.label === NETWORK}
            component={Link}
            href={option.label === NETWORK ? null : option.link}
            target={option.label === NETWORK ? null : "_blank"}
          >
            {option.label}
          </MenuItem>
        ))}
      </MenuPopover>
    </>
  );
}
